

/*
 * Ressources section
 */

.ressources {

  ul {
    display: inline-block;
  }

}
