th[role=columnheader]:not(.no-sort) {
  cursor: pointer;
}
th[role=columnheader]:not(.no-sort) {
  position: relative;
}
th[role=columnheader]:not(.no-sort):after {
  content: '';
  position: absolute;
  left: 0;
  top: 2.8rem;
  border-width: 0 4px 4px;
  border-style: solid;
  border-color: #404040 transparent;
  visibility: hidden;
  opacity: 0;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

th[aria-sort=ascending]:not(.no-sort):after {
  border-bottom: none;
  border-width: 4px 4px 0;
}

th[aria-sort]:not(.no-sort):after {
  visibility: visible;
  opacity: 0.4;
}

th[role=columnheader]:not(.no-sort):hover:after {
  visibility: visible;
  opacity: 1;
}