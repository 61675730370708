

.rule {
  background-image: url(../img/rule.svg);
  height: 30px;
}

.hero {
  color: $gray-900;

  .container {
    max-width: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    margin: 0;
    line-height: 1.2;
  }

  [class^="icon-"] { font-size: 4rem; }

  .text-vw {
    font-size: 1.2rem;
  }

  .pixel-vw {
    font-weight: bold;
    font-size: 4.8rem;
  }

  .rem-vw {
    font-size: 2.4rem;
  }

  [class*="button"] {
    font-size: 1.4rem;
    font-weight: bold;
    padding: $spacer-small-plus $spacer-medium;
    margin-top: $spacer-medium-plus;
    border-radius: 0;
    text-transform: uppercase;
    transition: color .20s;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: $black;
      box-shadow: 0 0 0 1px $black inset;
    }

  }

}


@include respond-to('small') {

  .hero {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

}


@include respond-to('small-up') {

  .hero {
    [class^="icon-"] { font-size: 8rem; }
    .text-vw  { font-size: 1.4rem; }
    .pixel-vw { font-size: 7.2rem; }
    .rem-vw   { font-size: 3.6rem; }
  }

}
