

/*
 * Meta viewport
 */

.meta-viewport {

  pre {
    display: inline-block;
    background-color: $gray-200;
    border: none;
  }

}
